<template>
  <vx-table
    :resource="resource"
    :columns="columns"
    :filters="filters"
  />
</template>

<script>
import {
  VxTable,
  VxCellDate,
  VxCellPlayerAvatar,
  VxCellButtons,
  VxCellBadges,
  VxCellAntifraudPoints,
  VxCellLink
} from '@/components/table'
import {
  antifraud,
  boolStatuses,
  passDataToResource
} from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { boolStatusesOptions, boolStatusFormat } from '@/services/utils'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'

export default {
  name: 'Activations',
  components: {
    VxTable,
    VxCellDate,
    VxCellPlayerAvatar,
    VxCellBadges,
    VxCellAntifraudPoints,
    VxCellLink,
    VxCellButtons
  },
  setup () {
    const resource = passDataToResource(antifraud.getAllActivations, {
      requestParams: {
        params: { include: 'player,inspector' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        label: 'Player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'point_log',
        label: 'Points Log',
        component: VxCellAntifraudPoints
      },
      {
        key: 'total_points',
        sortable: true
      },
      {
        key: 'is_appealed',
        label: 'Appealed',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'success',
            [boolStatuses['0']]: 'danger'
          }
        },
        formatter: (isAppealed) => boolStatusFormat(isAppealed)
      },
      {
        key: 'inspector',
        label: 'Reviewer',
        component: VxCellLink,
        tdAttr: (inspector) => {
          if (!inspector) return {}
          const { id, first_name, last_name, role: { title } } = inspector
          return {
            label: `${first_name} ${last_name}`,
            subLabel: `#${id} ${title}`,
            href: buildRoute(path.admin, { params: { id } })
          }
        }
      },
      {
        key: 'created_at',
        sortable: true,
        component: VxCellDate
      },
      {
        key: 'updated_at',
        sortable: true,
        component: VxCellDate
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item),
          vertical: true
        })
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'inspected_by',
        type: filterTypes.text
      },
      {
        key: 'is_appealed',
        label: 'Appealed',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      }
    ]

    const actionButtons = ({ id, is_appealed, inspected_by }) => {
      if (is_appealed) return []

      const payload = {
        requestParams: { urlParams: { id } }
      }

      const appealButton = {
        ...buttons.appeal,
        resource: passDataToResource(antifraud.appealActivation, payload)
      }
      const inspectButton = {
        ...buttons.inspect,
        resource: passDataToResource(antifraud.inspectActivation, payload)
      }
      const undoInspectButton = {
        ...buttons.undoInspect,
        resource: passDataToResource(antifraud.undoInspectActivation, payload)
      }

      return !inspected_by ? [appealButton, inspectButton] : [undoInspectButton]
    }

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
